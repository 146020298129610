/* eslint-disable no-lone-blocks */
import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import "monday-ui-react-core/dist/main.css";
import { Flex, List, Loader, Toast } from "monday-ui-react-core";
import SubItemTimerWidget from "./SubItemTimerWidget";

const TimerWidget = ({ boardId, accountId, item, userId, mondayUsers, dropdownOptions, checkRunningStatus, userData }) => {
  const [itemData, setItemData] = useState({ ...item });
  const [subitems, setSubItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const addSubitem = (subitems, subitem) => {
    const index = subitems.findIndex((object) => object.id === subitem.id);

    if (index === -1) {
      subitems.push(subitem);
    }
    setSubItems([...subitems]);
  };

  const removeSubItemData = (sitems, subitem) => {
    setLoading(true);
    setSubItems([...sitems.filter((item) => item.id != subitem)]);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const syncItem = async (item) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: item,
        accountId: accountId,
        boardId: boardId,
      }),
    };
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/save-monday`, options)
      .then(async (response) => await response.json())
      .then((data) => {
        item.subitems = data.data.sub_items;
        item.item_id = data.data.id;
        setSubItems(data.data.sub_items);
        setItemData(item);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const addStaff = async (itemData) => {
    setLoading(true);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        item_id: itemData.item_id,
        created_by: userId
      }),
    };
    const data = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/create-subitem`,
      options
    ).then(async (response) => await response.json());
    if (data.error) {
      item.item_id = data.data.id;
      setItemData(item);
      setSubItems(data.data.sub_items);
      setLoading(false);
      setErrorMessage(data.error);
      setError(true);
      return setTimeout(() => {
        setError(false);
      }, 2000);
    } else {
      item.item_id = data.data.id;
      setItemData(item);
      setSubItems(data.data.sub_items);
      setLoading(false);
    }
  };

  const removeSubItem = async (subitemId, itemId) => {
    setLoading(true);

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subitem_id: subitemId,
        item_id: itemId,
      }),
    };
    const data = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/remove-subitem`,
      options
    ).then(async (response) => await response.json());

    item.item_id = data.data.id;
    item.subitems = data.data.sub_items;
    setItemData(item);
    setSubItems(data.data.sub_items);
    setLoading(false);
  };

  useEffect(() => {
    syncItem(item);
  }, [item]);

  useEffect(() => {
    if (itemData.item_id && itemData.item_id !== undefined) {
      window.Echo.channel(`subitemCreated.${itemData.item_id}`).listen(
        "SubItemCreated",
        function (data) {
          addSubitem(subitems, data.subitem);
        }
      );
      window.Echo.channel(`subitemRemoved.${itemData.item_id}`).listen(
        "SubItemRemoved",
        function (data) {
          removeSubItemData(subitems, data.subitem);
        }
      );
    }
  }, [itemData, subitems]);


  const findindex = (subitem, dropdownOptions) => {
    let index = dropdownOptions.findIndex((el) => el.value == subitem?.user);
    return index;
  }

  return (
    <Flex gap={50} align="Center">
      {loading ? (
        <Loader size={Loader.sizes.SMALL} />
      ) : (
        <List>
          {[...subitems].map((subitem, index) => {
            return (
              <SubItemTimerWidget
                key={index}
                subitem={{ ...subitem }}
                itemBoardId={itemData.id}
                itemData={itemData}
                addStaff={addStaff}
                removeSubItem={removeSubItem}
                userId={userId}
                mondayUsers={mondayUsers}
                dropdownOptions={dropdownOptions}
                optionsIndex={findindex(subitem, dropdownOptions)}
                userData={userData}
                checkRunningStatus={checkRunningStatus}
              />
            );
          })}
        </List>
      )}
      <Toast
        open={error}
        type={Toast.types.NEGATIVE}
        autoHideDuration={5000}
        className="error-toaster"
        onClose={() => setError(false)}
      >
        {errorMessage}
      </Toast>
    </Flex>
  );
};

export default TimerWidget;

import React from "react";

const SignatureVerificationPage = () => {
  return (
    <div
      style={{
        position: "fixed",
        color: "#000",
        width: "100%",
        padding: "10px",
      }}
    >
      <h4 class="center-text">
        Signature Verification Failed
      </h4>
    </div>
  );
};

export default SignatureVerificationPage;

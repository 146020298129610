/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import "./App.css";
import "monday-ui-react-core/dist/main.css";
import {
  Button,
  ExpandCollapse,
  Flex,
  Icon,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "monday-ui-react-core";
import TimerWidget from "./TimerWidget";
import moment from "moment-timezone";
import { Time } from "monday-ui-react-core/icons";

const GroupWidget = ({
  group_id,
  keyData,
  groups,
  itemsData,
  boardId,
  accountId,
  userId,
  mondayUsers,
}) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [userData, setAccessUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [collapse, setCollapse] = useState(
    localStorage.getItem(`${group_id}_${boardId}`) &&
      localStorage.getItem(`${group_id}_${boardId}`) !== undefined
      ? true
      : false
  );
  const [items, setItems] = useState([...itemsData]);
  const [showIcon, setShowIcon] = useState(false);
  const checkRunningStatus = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        group_id: group_id,
        account_id: accountId,
        board_id: boardId,
      }),
    };
    setTimeout(() => {
      fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/check-running-subitems`,
        options
      ).then(async (response) => await response.json())
    }, 2000);
  };

  useEffect(() => {
    checkRunningStatus();
  }, [keyData]);

  useEffect(() => {
    window.Echo.channel(`checkRunningStatus.${group_id}`).listen(
      "CheckRunningStatus",
      function (data) {
        setShowIcon(data.data);
      }
    );
  }, []);

  useEffect(() => {
    setItems([...itemsData]);

    // let collapseData = localStorage.getItem('collapse');
  }, [itemsData]);

  const trimFullNameInTwoLetters = (fullName) => {
    const nameArr = fullName.split(" ");
    const firstNameInitials = nameArr[0].slice(0, 1).toUpperCase();
    const lastNameInitials = nameArr[nameArr.length - 1]
      .slice(0, 1)
      .toUpperCase();
    return `${firstNameInitials}${lastNameInitials}`;
  };

  useEffect(() => {
    let options = [];
    // console.log('monday', mondayUsers);
    for (let index = 0; index < mondayUsers.length; index++) {
      options.push({
        value: mondayUsers[index].monday_id,
        label: trimFullNameInTwoLetters(mondayUsers[index].name),
      });
    }
    setDropdownOptions(options);
    let AccessUserindex = options.findIndex((el) => el.value == userId);
    setAccessUser(options[AccessUserindex])

  }, [mondayUsers]);

  const addItem = (item, items) => {
    let newItems = items.filter((itemData) => itemData?.id !== item.item_id);
    setItems(newItems);
    setLoading(false);
  };

  useEffect(() => {
    if (group_id !== undefined) {
      window.Echo.channel(`itemCreated.${group_id}`).stopListening(
        "ItemCreated"
      );

      window.Echo.channel(`itemCreated.${group_id}`).listen(
        "ItemCreated",
        function (data) {
          addItem(data.item, items);
        }
      );
    }
  }, [group_id, items]);

  const columns = [
    {
      id: "items",
      title: "Items",
      loadingStateType: "medium-text",
      width: "75%",
    },
    {
      id: "widget",
      title: "Widget",
      loadingStateType: "medium-text",
      width: 362,
    },
  ];

  const getColor = (id) => {
    let colorData = "";
    let find = groups?.find((group) => group.id === id);
    colorData = find?.color ? find?.color : "red";
    return colorData;
  };

  const closeOpenCollaps = (e, group_id) => {
    if (collapse) {
      localStorage.removeItem(`${group_id}_${boardId}`);
      setCollapse(false);
    } else {
      localStorage.setItem(`${group_id}_${boardId}`, true);
      setCollapse(true);
    }
  };

  const stopGroupTime = (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    checkRunningStatus();
    const current = moment().tz("UTC").format("DD/MM/YYYY HH:mm:ss");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        group_id: group_id,
        now_time: current,
        userId: userId,
        account_id: accountId,
        board_id: boardId,
      }),
    };
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/stop-group-items-time`,
      options
    ).then(async (response) => await response.json());
  };

  const ExpandCollapseCustomHeadingComponent = () => {
    let groupName = groups?.find((group) => group.id === group_id)?.title;
    return (
      <Flex className="group-content">
        <h4 className="group-header">
          {groupName}{" "}
          {showIcon ? (
            <Icon
              className="group-header blink"
              iconType={Icon.type.SVG}
              icon={Time}
              iconLabel="my bolt svg icon"
              iconSize={22}
            />
          ) : null}
        </h4>
        {/* <Button
          className="group-header group-header-button"
          kind={Button.kinds.SECONDARY}
          size={Button.sizes.SMALL}
          onClick={(e) => stopGroupTime(e)}
        >
          Stop Timer
        </Button> */}
      </Flex>
    );
  };

  return (
    <div style={{ padding: 2, color: getColor(group_id) }}>
      <div className="expand-header">
        <ExpandCollapse
          key={keyData}
          headerComponentRenderer={ExpandCollapseCustomHeadingComponent}
          className="group-widget"
          open={collapse}
          componentClassName={`svg-colour group-widget-header ${
            collapse ? "expanded" : "collapsed"
          }`}
          onClick={(e) => closeOpenCollaps(e, group_id)}
        >
          <Table
            className="monday-table"
            dataState={{
              isLoading: loading,
            }}
            errorState={<></>}
            emptyState={<></>}
            columns={columns}
          >
            {loading ? (
              <Loader size={Loader.sizes.SMALL} />
            ) : (
              <TableBody className="monday-table-body">
                {items.map((rowItem) => (
                  <TableRow
                    key={rowItem.id}
                    className="adjust-height table-row"
                  >
                    <TableCell className="table-cell">{rowItem.name}</TableCell>
                    <TableCell className="table-cell cell-br-left">
                      <TimerWidget
                        item={rowItem}
                        boardId={boardId}
                        accountId={accountId}
                        userId={userId}
                        mondayUsers={mondayUsers}
                        dropdownOptions={dropdownOptions}
                        checkRunningStatus={checkRunningStatus}
                        userData={userData}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </ExpandCollapse>
        <Button
          className={`group-header-button ${
            collapse ? "group-header-button-expanded" : "group-header-button-collapsed"
          }`}
          kind={Button.kinds.SECONDARY}
          size={Button.sizes.SMALL}
          onClick={(e) => stopGroupTime(e)}
        >
          Stop Timer
        </Button>
      </div>
    </div>
  );
};

export default GroupWidget;

import React, { useState } from "react";
import "./App.css";
import "monday-ui-react-core/dist/main.css";
import { Modal, ModalContent, ModalFooterButtons } from "monday-ui-react-core";

const AddNotes = ({ openModalButtonRef, show, closeModal, subItemID, setData, subData }) => {
  const [note, setNote] = useState(subData?.note ?? '');

  // useEffect(() => {
  //   setNote(subData?.note);
  // }, [subData]);

  const submit = async () => {
    closeModal();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subitem_id : subData?.id,
        note: note
      }),
    };
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/update-note`, options)
      .then(async (response) => await response.json())
      .then((data) => {
      })
      .catch((error) => {

      });
  };

  return (
    <>
      <Modal
        id="story-book-modal"
        title="Notes"
        triggerElement={openModalButtonRef}
        show={show}
        onClose={closeModal}
        width={Modal.width.DEFAULT}
        contentSpacing
      >
        <ModalContent>
          <div>
            <textarea value={note} placeholder='Type...' className="note-textarea" name="note" rows={8} cols={65} onChange={(e) => setNote(e.target.value)}/>
          </div>
        </ModalContent>
        <ModalFooterButtons
          primaryButtonText="Submit"
          onPrimaryButtonClick={() => submit()}
        />
      </Modal>
    </>
  );
};

export default AddNotes;

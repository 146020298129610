import React, { memo } from "react";
import { useState, useEffect } from "react";
import "./App.css";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css";
import _ from "lodash";
import GroupWidget from "./GroupWidget";
import Echo from "laravel-echo";
import CookieConsentBanner from "./CookieConsentBanner";
import SignatureVerificationPage from "./signatureVerificationPage";
import { Link, Loader } from "monday-ui-react-core";
import Cookies from "js-cookie";
import {
  ExternalPage
} from "monday-ui-react-core/icons";
window.io = require("socket.io-client");

const monday = mondaySdk();

const App = () => {
  const [boardId, setBoardId] = useState();
  const [accountId, setAccountId] = useState();
  const [itemsData, setItems] = useState({});
  const [loading, setLoading] = useState(true);
  const [signatureVerificationPage, setSignatureVerificationPage] =
    useState(false);
  const [groups, setGroups] = useState([]);
  const [userId, setUserId] = useState(null);
  const [mondayUsers, setMondayUsers] = useState([]);
  const [userViwer, setUserViewer] = useState(false);
  const [showPopup, setShowPopup] = useState(!Cookies.get('cookieConsent'));

  useEffect(() => {
    monday.execute("valueCreatedForUser");
  }, []);

  useEffect(() => {
    if (typeof io !== "undefined") {
      window.Echo = new Echo({
        broadcaster: "socket.io",
        host: `${process.env.REACT_APP_SOCKET_URL}`,
      });
    }

    monday.listen("context", (res) => {
      setUserId(res.data.user.id);
      setUserViewer(res.data.user.isViewOnly);
      // setUserViewer(res.data.user.isAdmin);
      setBoardId(res.data.boardId);
      setAccountId(res.data.account.id);
    });

    setLoading(true);
    monday.get("sessionToken").then((token) => {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: token.data }),
      };
      fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/verify-token`, options)
        .then(async (response) => await response.json())
        .then((data) => {
          if (data?.error) {
            setSignatureVerificationPage(true);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => { });
    });

    monday
      .api(`query { users { id, name, time_zone_identifier }  account { id } }`)
      .then((res) => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accountId: res.data?.account?.id ?? 23685495,
            users: res?.data?.users,
          }),
        };
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/create-users`, options)
          .then(async (response) => await response.json())
          .then((data) => {
            console.log(data.data);
            setMondayUsers(data?.data);
          })
          .catch((error) => { });
      });
  }, []);

  const createWebhook = async (boardId) => {
    monday
      .api(
        `query {
      webhooks(board_id: ${boardId}){
        id
        event
        board_id
        config
      }
    }`
      )
      .then((res) => {
        let webhookData = res.data.webhooks.find(
          (webhook) => webhook.event === "create_item"
        );
        if (!webhookData) {
          monday
            .api(
              `mutation {
          create_webhook (board_id: ${boardId}, url: "${process.env.REACT_APP_BACKEND_WEBHOOK_URL}/create-item-webhook", event: create_item ) {
            id
            board_id
          }
            }`
            )
            .then((res) => {
            });
        }
      });
  };

  const createBoard = async (boardId, accountId) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        boardId: boardId,
        accountId: accountId,
      }),
    };
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/create-board`, options)
      .then(async (response) => await response.json())
      .then((data) => { })
      .catch((error) => { });
  };

  const addGroup = (group_id, items, group_name, itemsData, groups) => {
    setItems({
      ...itemsData,
      [group_id]: [...items],
    });

    setGroups([
      ...groups,
      {
        id: group_id,
        title: group_name,
      },
    ]);
  };

  useEffect(() => {
    if (boardId) {
      monday
        .api(
          `query {
      boards (ids: ${parseInt(boardId)}){
        items_page (limit: 200) {
          cursor
          items {
            group { id title color }
            id 
            name
            subitems {
              column_values {
                column {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }`
        )
        .then((res) => {
          let groupData = _.groupBy(
            res?.data?.boards[0]?.items_page?.items,
            "group.id"
          );
          setItems(groupData);
          setGroups(
            res?.data?.boards[0]?.items_page?.items?.map((item) => {
              return {
                id: item.group?.id,
                title: item.group?.title,
                color: item.group?.color,
              };
            })
          );
          setLoading(false);
        });

      createWebhook(boardId);
      createBoard(boardId, accountId);
    }
  }, [boardId, accountId]);

  useEffect(() => {
    window.Echo.channel(`groupCreated.${boardId}`).listen(
      "GroupCreated",
      function (data) {
        const group_id = data.item.group.group_id;
        const items = [
          {
            group: {
              id: data.item.group.group_id,
              title: data.item.group.group_name,
            },
            name: data.item.item,
            id: data.item.item_id,
          },
        ];

        addGroup(
          group_id,
          items,
          data.item.group.group_name,
          itemsData,
          groups
        );
      }
    );
  }, [boardId, accountId, itemsData, groups]);

  // const exportHandler = async () => {
  //   const options = {
  //     method: "GET",
  //   };
  //   fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/export-excel`, options)
  //     .then((data) => {})
  //     .catch((error) => {});
  // };

  const handleAccept = () => {
    // Set a cookie or perform any other action here
      Cookies.set("cookieConsent", "accepted", {
      secure: true,
      sameSite: 'None',
    });
    setShowPopup(false);
  };
  const handleDelete = () => {
    Cookies.remove('cookieConsent') 
  };

  return (
    <div className="app-padding">
      {loading ? (
        <Loader size={Loader.sizes.MEDIUM} color={Loader.colors.PRIMARY} />
      ) : (
        <>
          {signatureVerificationPage ? (
            <SignatureVerificationPage />
          ) : (
            <>
              {userViwer ?
                <>
                  <h4 className="center-text">
                    As a viewer, you are unable to use the  Smart Timer and Team Tracking - Marketplace Review
                  </h4>
                </>
                :
                <>
                  {showPopup && <CookieConsentBanner onAccept={handleAccept} />}
                  <Link
                    href="https://www.appbay.com.au/apps/how-to-use-smart-timer-and-team-tracking"
                    text="How To Use"
                    target="_blank"
                    inheritFontSize
                    iconPosition={Link.iconPositions.END}
                    icon={ExternalPage}
                    className="appbay-link"
                  />
                  {/* <CookieConsentBanner /> */}
                  {Object.keys(itemsData).map((i, key) => {
                    return (
                      <GroupWidget
                        group_id={i}
                        keyData={key}
                        groups={groups}
                        loading={loading}
                        itemsData={itemsData[i]}
                        boardId={boardId}
                        accountId={accountId}
                        userId={userId}
                        mondayUsers={mondayUsers}
                      />
                    );
                  })}
                </>
              }
            </>
          )}
        </>
      )}
    </div>
  );
};

export default memo(App);

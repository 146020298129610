import React from 'react';

const CookieConsentBanner = ({ onAccept }) => {
  return (
    <div className="popup-banner">
    <div className="popup-content">
      <h2 className="popup-title">We Value Your Privacy</h2>
      <p className="popup-text">
        We use cookies to ensure you get the best experience on our website. By continuing, you agree to our cookie policy.
      </p>
      <button className="popup-button" onClick={onAccept}>
        Accept & Continue
      </button>
    </div>
  </div>
  );
};

export default CookieConsentBanner;

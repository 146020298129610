/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable no-lone-blocks */
import React, { useCallback, useRef } from "react";
import { useState, useEffect } from "react";
import "./App.css";
import "monday-ui-react-core/dist/main.css";
import mondaySdk from "monday-sdk-js";
import {
  Dropdown,
  Flex,
  Loader,
  Menu,
  MenuButton,
  MenuItem,
  Toast,
} from "monday-ui-react-core";
import moment from "moment-timezone";
import {
  Checkbox,
  AddSmall,
  Delete,
  Time,
  Note,
  Recurring,
} from "monday-ui-react-core/icons";
import AddNotes from "./AddNotesModal";
import UpdateTime from "./UpdateTimeModal";

const monday = mondaySdk();

const SubItemTimerWidget = ({
  subitem,
  itemBoardId,
  itemData,
  addStaff,
  removeSubItem,
  userId,
  dropdownOptions,
  optionsIndex,
  checkRunningStatus,
  selectedUserIndex,
  userData,
  mondayUsers
}) => {
  const [item, setItem] = useState({ ...itemData });
  const [subitemData, setSubItemData] = useState({ ...subitem });
  const [timer, setTimer] = useState("");
  const [time, setTime] = useState(0);
  const [runningInterval, setRunningInterval] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [show, setShow] = useState(false);
  const openModalButtonRef = useRef(null);
  const [showAddNote, setShowAddNote] = useState(false);
  const openNoteModalButtonRef = useRef(null);
  const [defultIndex, setDefultIndex] = useState(dropdownOptions[optionsIndex]);
  const [selectedUser, setSelectedUser] = useState(subitemData?.user);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [processingTime, setProcessingTime] = useState(false);
  const [selectedSubitemId, setSelectedSubitemId] = useState(subitemData?.id);

  useEffect(() => {
    setItem(itemData);
  }, [itemData]);

  const trimFullNameInTwoLetters = (fullName) => {
    const nameArr = fullName.split(" ");
    const firstNameInitials = nameArr[0].slice(0, 1).toUpperCase();
    const lastNameInitials = nameArr[nameArr.length - 1]
      .slice(0, 1)
      .toUpperCase();
    return `${firstNameInitials}${lastNameInitials}`;
  };

  const setIndexhandler = (e) => {
    let options = [];
    for (let index = 0; index < mondayUsers.length; index++) {
      options.push({
        value: mondayUsers[index].monday_id,
        label: trimFullNameInTwoLetters(mondayUsers[index].name),
      });
    }
    let AccessUserindex = options.findIndex((el) => el.value == e);
    setDefultIndex(options[AccessUserindex])
  };

  useEffect(() => {
    // calculate(subitem.time, subitem.start_time);

    // if (subitem.start_time) {
    //   startInterval(subitem.time, subitem.start_time);
    // }

    window.Echo.channel(`subitemUpdate.${subitem.id}`).listen(
      "SubitemUpdated",
      async function (data) {
        startInterval(data.subitem.time, data.subitem.current);
        setIndexhandler(data.subitem.user);
        setSelectedUser(data.subitem.user);
        setSubItemData(data.subitem);
        calculate(data.subitem.time, data.subitem.start_time);
      }
    );

    window.Echo.channel(`saveSubitemTime.${subitem.id}`).listen(
      "SaveSubitemTime",
      function (data) {
        setIndexhandler(data.subitem.user);
        setSubItemData(data.subitem);
      }
    );
  }, [subitem]);

  useEffect(() => {
    if (runningInterval) {
      clearInterval(runningInterval);
      setRunningInterval(null);
    }
    calculate(subitemData.time, subitemData.start_time);

    if (subitemData.start_time) {
      startInterval(subitemData.time, subitemData.start_time);
    }
  }, [subitemData, subitem]);

  const startInterval = (time, start_time = null) => {
    if (runningInterval) {
      clearInterval(runningInterval);
    }

    if (start_time) {
      const interval = setInterval(() => {
        calculate(time, start_time);
      }, 1000);
      setRunningInterval(interval);
    }
  };

  const formatSeconds = (s) => {
    if (s >= 3600) {
      return [parseInt(s / 60 / 60), parseInt((s / 60) % 60), parseInt(s % 60)]
        .join(":")
        .replace(/\b(\d)\b/g, "0$1");
    } else {
      return [parseInt((s / 60) % 60), parseInt(s % 60)]
        .join(":")
        .replace(/\b(\d)\b/g, "0$1");
    }
  };

  const calculate = (time, start_time = null) => {
    const current = moment().tz("UTC").format("DD/MM/YYYY HH:mm:ss");
    let seconds = parseInt(time === undefined ? 0 : time);
    if (start_time) {
      let duration = moment.duration(
        moment(`${current}`, "DD/MM/YYYY HH:mm:ss").diff(
          moment(`${start_time}`, "DD/MM/YYYY HH:mm:ss")
        )
      );

      seconds = parseInt(duration.asSeconds()) + parseInt(time);
      seconds = seconds < 0 ? 0 : seconds;
    }

    setTime(seconds);
    const text = formatSeconds(seconds);

    setTimer(`${text}`);
  };

  const dropDownOnchange = (e) => {
    setSelectedUser(e?.value ? e?.value : null);
  };

  const createSubitem = async (res, currentDate, userData) => {
    await monday.api(`query { items (ids:  ${parseInt(res?.data?.create_subitem?.id)}) {name board { id } column_values { id column { title } } }}`).then(async (ress) => {
      const values = ress.data.items[0];
      const personId = values.column_values.find((el) => el.column.title == 'Owner')?.id;
      const dateId = values.column_values.find((el) => el.column.title == 'Date')?.id;

      let newQuery = `mutation {
                        change_multiple_column_values(
                          item_id: ${res?.data?.create_subitem?.id},
                          board_id: ${ress.data.items[0].board.id}, 
                          column_values: "{\\\"${dateId}\\\" : {\\\"date\\\" : \\\"${currentDate}\\\"}, \\\"${personId}\\\" : {\\\"personsAndTeams\\\":[{\\\"id\\\":\\\"${userData}\\\",\\\"kind\\\":\\\"person\\\"}] } }"
                          ) { id }}`
      await monday.api(newQuery).then((resss) => {
        setProcessingTime(false);
      }).catch((error) => {
        createSubitem(res, currentDate, userData);
      })
    })
  }

  const saveTime = async (itemData, type, timer) => {
    if (selectedUser && selectedUser != userId) {
      setErrorMessage("Access denied");
      setError(true);
      return setTimeout(() => {
        setError(false);
      }, 3000);
    }

    const current = moment().tz("UTC").format("DD/MM/YYYY HH:mm:ss");

    if (runningInterval) {
      clearInterval(runningInterval);
      setRunningInterval(null);
    }

    setSaveLoading(true);
    if (type === "start") {
      setSubItemData({ ...itemData, start_time: current });
    }
    setSelectedUser(userId);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subitem_id: itemData.id,
        item_id: itemData.item_id,
        type: type,
        start_time: current,
        time: time,
        user: userId,
      }),
    };
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/update-subitem`, options)
      .then(async (response) => await response.json())
      .then(async (data) => {
        if (data.error) {
          setErrorMessage(data.error);
          setError(true);
          setDefultIndex(null);
          setSaveLoading(false);
          return setTimeout(() => {
            setError(false);
          }, 30000);
        } else {
          // if (type === "start") {
          //   calculate(itemData.time, current);
          //   startInterval(itemData.time, current);
          // }
        }
        setSaveLoading(false);

        let subItem = { ...subitemData };
        subItem.complete = data.data.complete;
        subItem.start_time = data.data.start_time;
        subItem.time = data.data.time;
        subItem.subitem_id = data.data.id;
        subItem.note = data.data.note;
        subItem.user = data.data.user;

        setSubItemData({ ...subItem });
        if (data.error) {
          setErrorMessage(data.error);
          setError(true);
          return setTimeout(() => {
            setError(false);
          }, 2000);
        }

        checkRunningStatus();
        if (type === "complete") {
          const currentDate = moment().tz("UTC").format("YYYY-MM-DD");

          // setSubItemData({ ...itemData, start_time: null, complete: true });
          // let totalTimeInMinutes = parseInt(time)
          //   ? parseFloat(parseInt(time) / 60).toFixed(2)
          //   : 0;
          const minutes = Math.floor(time / 60);
          const remainingSeconds = time % 60;
          let totalTimeInMinutes = `${minutes}.${remainingSeconds}`
          if (userId) {
            setProcessingTime(true);
            if (data.data.subitem_id) {
              await monday.api(`query { items (ids:  ${parseInt(data.data.subitem_id)}) {name board { id } column_values { id column { title } } }}`).then(async (res) => {
                const values = res?.data?.items[0];
                const dateId = values.column_values.find((el) => el.column.title == 'Date')?.id;
                await monday.api(`mutation {
                    change_multiple_column_values(
                      item_id: ${data.data.subitem_id},
                      board_id: ${res.data.items[0].board.id},
                      column_values: "{\\\"name\\\": \\\"${totalTimeInMinutes}\\\", \\\"${dateId}\\\" : {\\\"date\\\" : \\\"${currentDate}\\\"}}"        
                      ) { id }}`).then((resp) => {
                  setProcessingTime(false);
                })
              })

            } else {
              await monday
                .api(
                  `mutation { create_subitem( parent_item_id: ${parseInt(
                    itemBoardId
                  )} item_name: \"${totalTimeInMinutes}\" create_labels_if_missing: true ) { id } }`
                )
                .then(async (res) => {
                  await createSubitem(res, currentDate, userId);

                  const updateOptions = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      id: subItem?.id,
                      subitem_id: res?.data?.create_subitem?.id
                    }),
                  };
                  fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/update-monday-subitem`, updateOptions)
                    .then(async (data) => { });
                });
            }

          }
        }
      })
      .catch((error) => {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: error,
            test: 'test'
          }),
        };
        fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/update-catch-api`, options)
          .then(async (response) => await response.json())
        setSaveLoading(false);
      });
  };

  const closeNoteModal = useCallback(() => {
    setShowAddNote(false);
  }, []);

  const openNoteModalButton = () => {
    setShowAddNote(true);
  };

  const closeModal = useCallback(() => {
    setShow(false);
  }, []);

  const openModalButton = (subitemId) => {
    setSelectedSubitemId(subitemId);
    setShow(true);
  };

  const showtimeFormat = (time) => {
    // Split the input time by colon to get parts
    let parts = time.split(":");

    // Check if the input has hours, minutes, and seconds or just minutes and seconds
    if (parts.length === 3) {
      // Input has hours, minutes, and seconds
      let hours = parseInt(parts[0], 10);
      let minutes = parseInt(parts[1], 10);
      let seconds = parseInt(parts[2], 10);
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (parts.length === 2) {
      // Input has only minutes and seconds
      let minutes = parseInt(parts[0], 10);
      let seconds = parseInt(parts[1], 10);
      return `${minutes}m ${seconds}s`;
    } else {
      return "Invalid time format";
    }
  };
  return (
    <>
      <Flex gap={2} align="Center" className="mt-2">
        {saveLoading ?
          <div className="timer-box timer-font">
            <Loader size={Loader.sizes.SMALL} />
          </div>
          :
          <>
            {/* <Flex
        // className={`timer-container ${
        //   !subitemData?.complete &&
        //   !subitemData?.start_time &&
        //   subitemData?.time > 0
        //     ? "stop-colour"
        //     : !subitemData?.complete && subitemData?.start_time
        //     ? "running-colour"
        //     : subitemData?.complete
        //     ? "complete-colour"
        //     : "default-colour"
        // }`}
        > */}
            {/* white-space: nowrap;
            font-size: 14px; */}
            <div className="timer-box timer-font">{showtimeFormat(timer)}</div>
            <div className="timer-box">
              {!subitemData?.complete &&
                !subitemData?.start_time &&
                !subitemData?.user ? (
                // <Icon
                //   iconType={Icon.type.SVG}
                //   icon={Play}
                //   iconLabel="my bolt svg icon"
                //   iconSize={20}
                //   onClick={() => {
                //     if (!selectedUser) {
                //       setDefultIndex(userData);
                //     }
                //     let type = "start";
                //     saveTime(subitemData, type);
                //   }}
                // />
                <div
                  style={{
                    color: "#5d17eaff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!selectedUser) {
                      setDefultIndex(userData);
                    }
                    let type = "start";
                    saveTime(subitemData, type);
                  }}
                >
                  <i class="fa fa-play-circle fa-lg" aria-hidden="true"></i>
                </div>
              ) : null}
              {!subitemData?.complete &&
                !subitemData?.start_time &&
                subitemData?.user ? (
                // <Icon
                //   iconType={Icon.type.SVG}
                //   icon={Play}
                //   iconLabel="my bolt svg icon"
                //   iconSize={20}
                //   onClick={() => {
                //     if (!selectedUser) {
                //       setDefultIndex(userData);
                //     }
                //     let type = "start";
                //     saveTime(subitemData, type);
                //   }}
                // />
                <div
                  style={{
                    color: "#ff8800",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!selectedUser) {
                      setDefultIndex(userData);
                    }
                    let type = "start";
                    saveTime(subitemData, type);
                  }}
                >
                  <i class="fa fa-play-circle fa-lg" aria-hidden="true"></i>
                </div>
              ) : null}
              {!subitemData?.complete && subitemData?.start_time ? (
                // <Icon
                //   iconType={Icon.type.SVG}
                //   icon={Pause}
                //   iconLabel="my bolt svg icon"
                //   iconSize={20}
                //   onClick={() => {
                //     let type = "pause";
                //     saveTime(subitemData, type, timer);
                //   }}
                // />
                <div
                  style={{
                    color: "#ff4500",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let type = "pause";
                    saveTime(subitemData, type, timer);
                  }}
                >
                  <i class="fa fa-pause-circle fa-lg" aria-hidden="true"></i>
                </div>
              ) : null}

              {subitemData?.complete && (
                <div
                  style={{
                    color: "#6bd904",
                    cursor: "pointer",
                  }}
                >
                  {/* <Icon
                iconType={Icon.type.ICON_FONT}
                icon={Completed}
                iconLabel="Complete"
                iconSize={20}
                clickable={false}
                useCurrentColor
              /> */}
                  <i class="fa fa-check-circle fa-lg" aria-hidden="true"></i>
                </div>
              )}
            </div>


            <div className="user-dropdown">
              <Dropdown
                size={Dropdown.sizes.MEDIUM}
                onChange={(e) => dropDownOnchange(e)}
                options={dropdownOptions}
                className="dropdown-stories-styles_big-spacing"
                dropdownMenuWrapperClassName="user-dropdown-active"
                insideOverflowContainer
                disabled
                value={defultIndex}
              // defaultValue={[defultIndex]}
              />
            </div>

            <MenuButton
              size={MenuButton.sizes.LARGE}
              onMenuHide={function noRefCheck() { }}
              onMenuShow={function noRefCheck() { }}
              closeMenuOnItemClick
              className="rotate"
            >
              <Menu id="menu" size="medium">
                {!subitemData?.complete ? (
                  <MenuItem
                    icon={Checkbox}
                    iconType="SVG"
                    title="Task Complete"
                    onClick={() => {
                      let type = "complete";
                      saveTime(subitemData, type);
                    }}
                  />
                ) : null}
                {subitemData?.complete ? (
                  <MenuItem
                    icon={Recurring}
                    iconType="SVG"
                    title="Reopen"
                    onClick={() => {
                      let type = "restart";
                      saveTime(subitemData, type);
                    }}
                    disabled={selectedUser !== userId}
                  />
                ) : null}
                {!subitemData?.start_time ? (
                  <MenuItem
                    icon={Time}
                    iconType="SVG"
                    title="Update Time"
                    ref={{
                      current: "[Circular]",
                    }}
                    onClick={() => {
                      openModalButton(subitemData?.id);
                    }}
                    disabled={selectedUser !== userId}
                  />
                ) : null}
                {itemData?.subitems.length > 1 ? (
                  <MenuItem
                    icon={Delete}
                    iconType="SVG"
                    title="Remove"
                    onClick={() => {
                      removeSubItem(subitemData.id, itemData.item_id);
                    }}
                    disabled={selectedUser !== userId}
                  />
                ) : null}

                <MenuItem
                  icon={AddSmall}
                  iconType="SVG"
                  onClick={() => {
                    addStaff(itemData);
                  }}
                  title="Add Staff"
                />
                <MenuItem
                  icon={Note}
                  iconType="SVG"
                  onClick={() => {
                    openNoteModalButton();
                  }}
                  title="Notes"
                />
              </Menu>
            </MenuButton>
          </>
        }
      </Flex >
      <AddNotes
        openModalButtonRef={openNoteModalButtonRef.current}
        show={showAddNote}
        closeModal={closeNoteModal}
        subItemID={selectedSubitemId}
        setData={setSubItemData}
        subData={subitemData}
      />
      <UpdateTime
        openModalButtonRef={openModalButtonRef.current}
        show={show}
        closeModal={closeModal}
        subItemID={selectedSubitemId}
        setData={setSubItemData}
        subData={subitemData}
      />
      <Toast
        open={error}
        type={Toast.types.NEGATIVE}
        autoHideDuration={5000}
        className="error-toaster"
        onClose={() => setError(false)}
      >
        {errorMessage}
      </Toast>
      <Toast
        icon="Warning"
        loading
        open={processingTime}
        className="warning-toaster"
        closeable={false}
        type={Toast.types.NEGATIVE}
      >
        Processing. Please do not close the tab.
      </Toast>
    </>
  );
};

export default SubItemTimerWidget;

/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import "./App.css";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css";
import {
  Modal,
  ModalContent,
  ModalFooterButtons,
  Toast,
} from "monday-ui-react-core";
import moment from "moment-timezone";

const monday = mondaySdk();

const UpdateTime = ({ openModalButtonRef, show, closeModal, subItemID, setData, subData }) => {
  const [time, setTime] = useState(0);
  const [processingTime, setProcessingTime] = useState(false);

  const onlyNumberPrice = (event) => {
    let keyCode = event.keyCode ? event.keyCode : event.which;
    if ((keyCode < 47 || keyCode > 57) && keyCode !== 8 && (keyCode < 95 || keyCode > 106)) {
      event.preventDefault();
    }
  };

  const submit = () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subitemID: subItemID,
        time: time
      }),
    };

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/update-time`, options)
      .then(async (response) => await response.json())
      .then(async (data) => {
        closeModal();
        let subItem = { ...subData };
        subItem.complete = data.data.complete;
        subItem.start_time = data.data.start_time;
        subItem.time = data.data.time;
        subItem.subitem_id = data.data.id;
        subItem.note = data.data.note;
        setData({ ...subItem });
        setTime(null);


        if (data.data.subitem_id) {
          setProcessingTime(true);
          const currentDate = moment().tz("UTC").format("YYYY-MM-DD");

          // let totalTimeInMinutes = parseInt(subItem.time)
          //   ? parseFloat(parseInt(subItem.time) / 60).toFixed(2)
          //   : 0;
          const minutes = Math.floor(subItem.time / 60);
          const remainingSeconds = subItem.time % 60;
          let totalTimeInMinutes = `${minutes}.${remainingSeconds}`

          await monday.api(`query { items (ids:  ${parseInt(data.data.subitem_id)}) {name board { id } column_values { id column { title } } }}`).then(async (res) => {
            const values = res.data.items[0];
            const dateId = values.column_values.find((el) => el.column.title == 'Date')?.id;
            const query = `mutation {
              change_multiple_column_values(
                item_id: ${data.data.subitem_id},
                board_id: ${res.data.items[0].board.id}, 
                column_values: "{\\\"name\\\": \\\"${parseFloat(totalTimeInMinutes)}\\\", \\\"${dateId}\\\" : {\\\"date\\\" : \\\"${currentDate}\\\"}}"
                ) { id }}`
            await monday.api(query).then((resp) => {
              setProcessingTime(false);
            })
          })
        }
      })
      .catch((error) => { });
  }

  return (
    <>
      <Modal
        id="story-book-modal"
        title="Update Time"
        triggerElement={openModalButtonRef}
        show={show}
        onClose={closeModal}
        width={Modal.width.DEFAULT}
        contentSpacing
      >
        <ModalContent>
          <div>
            <label>Seconds</label>
          </div>
          <div>
            <input
              className="time-input"
              name="myInput"
              onChange={(e) => setTime(e.target.value)}
              maxlength={5}
              minlength={1}
              onKeyDown={(e) => onlyNumberPrice(e)}
              onPaste={(e) => onlyNumberPrice(e)}
              autoComplete='off'
            />
          </div>
        </ModalContent>
        <ModalFooterButtons
          primaryButtonText="Submit"
          onPrimaryButtonClick={submit}
        />
      </Modal>
      <Toast
        icon="Warning"
        loading
        open={processingTime}
        className="warning-toaster"
        closeable={false}
        type={Toast.types.NEGATIVE}
      >
        Processing. Please do not close the tab.
      </Toast>
    </>
  );
};

export default UpdateTime;
